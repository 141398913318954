<template>
    <div class="zx-upload-image">
        <el-upload
            :action="action"
            :data="actionParams"
            :limit="limit"
            list-type="picture-card"
            :accept="accept"
            :file-list=fileList
            :on-preview="handlePictureCardPreview"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
            :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 1)"
            :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" :append-to-body='appendToBody'>
        <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>
<script>
import { uploadImage } from '../../api/image-upload';

export default {
    name: 'ZhiXinImageUpload',
    props: {
        action: {
            type: String,
            default: '/v1/admin/Classcenter/uploadAd',
        },
        actionParams: {
            type: Object,
            default: () => ({}),
        },
        value: {
            type: Array,
            default: () => [],
        },
        limit: {
            type: Number,
            default: 1,
        },
        accept: {
            type: String,
            default: '.png,.jpg,.jpeg',
        },
        validateFun: {
            type: Function,
            default: null,
        },
        appendToBody: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
        };
    },
    methods: {
        // 移除文件的回调
        handleRemove (file, fileList) {
            this.fileList.forEach((item, index) => {
                if (item.uid === file.uid) {
                    this.fileList.splice(index, 1);
                }
            });
        },
        // 预览图片
        handlePictureCardPreview (file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        validFileAccept (file) {
            let valid = false;
            const accepts = this.accept.split(',');
            for (let i = 0; i < accepts.length; i++) {
                const fix = accepts[i];
                if ((file.name + '').indexOf(fix) > 0) {
                    valid = true;
                }
            }
            return valid;
        },
        beforeUpload (file) {
            if (!this.validFileAccept(file)) {
                this.$message.warning(`文件类型不支持`);
                return false;
            }
            if (this.validateFun) {
                var _this = this;
                return new Promise(function (resolve, reject) {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function (theFile) {
                        let image = new Image();
                        image.src = theFile.target.result;
                        image.onload = function () {
                            let state = _this.validateFun(image, file);
                            if (state) {
                                resolve(file);
                            } else {
                                reject(file);
                            }
                        };
                    };
                });
            } else {
                return true;
            }
        },
        handleImageUrl (imageData) {
            const parts = imageData.split('/');
            const lastPart = parts.pop();
            return parts.join('/') + '/' + encodeURIComponent(lastPart);
        },
        // 上传图片成功回调
        handleSuccess (response, file, fileList) {
            if (response.code === 1) {
                fileList.forEach((item) => {
                    item.response.encodeUrl = this.handleImageUrl(item.response.pic_url);
                });
                this.fileList = fileList;
            } else {
                // Start Generation Here
                this.fileList = fileList.filter((f) => {
                    return f.response && f.response.code === 1;
                });
                this.$message.warning(response.msg);
            }
        },
        // 文件超出限制个数时的回调
        handleExceed () {
            this.$message.warning(`当前限制选择 ${this.limit} 个文件`);
        },
    },
    mounted () {
        // console.log(this.accept);
    },
    computed: {
        uploadImageFunction () {
            return this.action || uploadImage;
        },
        fileList: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.zx-upload-image {
    /deep/input[type="file"] {
        display: none !important;
    }
}
</style>
